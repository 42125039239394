import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import Util from "./util";

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "simulator") {
    Util.debug("Loading Simulator");
    return import("./AppSimulator");
  } else {
    Util.debug("Loading App");
    return import("./App");
  }
}

importBuildTarget().then(({ default: Application }) =>
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <Application />
    </React.StrictMode>
  )
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
