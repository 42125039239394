import { v4 as uuidv4 } from "uuid";
import {
  hasLocalStorage,
  setItem,
  getItem,
  removeItem
} from "@analytics/localstorage-utils";
import { hasCookies, setCookie, getCookie, removeCookie } from "@analytics/cookie-utils";
import {
  hasSessionStorage,
  getSessionItem,
  setSessionItem,
} from "@analytics/session-storage-utils";

class Util {
  static log = null;

  static isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  static isUndefined = (node) => {
    return !node || node === null || node === undefined || node === "undefined";
  };
  static getHostName = () => {
    return document.location.hostname;
  };
  static getApiEndpoint = () => {
    if (
      process.env.REACT_APP_API_Location &&
      process.env.REACT_APP_API_Location.length > 0
    ) {
      const orgName = this.getHostName().replace(".widget.qualifico.com", "");
      return process.env.REACT_APP_API_Location.replace("[orgName]", orgName);
    }
    return `${document.location.protocol}//${this.getHostName().replace(
      ".widget.qualifico.com",
      ".api.widget.qualifico.com"
    )}`;
  };
  static getMqttOptions = (tenantId, visitorId) => {
    const options = {
      keepalive: 0,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 2000,
      connectTimeout: 30 * 1000,
      will: {
        topic: `${tenantId}/visitors/${visitorId}`,
        qos: 0,
        payload: JSON.stringify({
          id: uuidv4(),
          sender: { type: "visitor", id: visitorId },
          type: "disconnect",
          format: "date",
          value: "Visitor Left",
          time: new Date().toISOString(),
        }),
        retain: false,
      },
      rejectUnauthorized: false,
    };
    options.clientId = `${tenantId}_v_${visitorId}`;
    options.username = visitorId;
    //options.password = password;
    return options;
  };
  static debug = (message, param) => {
    if (this.log === null) {
      const urlParams = new URLSearchParams(document.location.search);
      this.log = urlParams.get("log") === "on" || process.env.REACT_APP_BUILD_TARGET === "simulator" ? true : false;
    }
    if (this.log) console.log(message, param);
  };

  static storage = {
    set: (key, value) => {
      const keyPrefix = getKeyPrefix();
      if (hasLocalStorage()) {
        setItem(`${keyPrefix}_${key}`, JSON.stringify(value));
      } else if (hasCookies()) {
        setCookie(`${keyPrefix}_${key}`, JSON.stringify(value), 60*60*24*365);
      }
    },
    get: (key) => {
      const keyPrefix = getKeyPrefix();
      if (hasLocalStorage()) {
        const value = getItem(`${keyPrefix}_${key}`);
        if (value && value !== "undefined") {
          return JSON.parse(value);
        }
        return null;
      } else if (hasCookies()) {
        const value = getCookie(`${keyPrefix}_${key}`);
        if (value && value !== "undefined") {
          return JSON.parse(value);
        }
        return null;
      }
    },
    remove: (key) => {
      const keyPrefix = getKeyPrefix();
      if (hasLocalStorage()) {
        removeItem(`${keyPrefix}_${key}`);
      } else if (hasCookies()) {
        removeCookie(`${keyPrefix}_${key}`);
      }
    },
  };

  static session = {
    set: (key, value) => {
      const keyPrefix = getKeyPrefix();
      if (hasSessionStorage()) {
        setSessionItem(`${keyPrefix}_${key}`, JSON.stringify(value));
      } else if (hasCookies()) {
        setCookie(`${keyPrefix}_${key}`, JSON.stringify(value), 60*30);
      }
    },
    get: (key) => {
      const keyPrefix = getKeyPrefix();
      if (hasSessionStorage()) {
        const value = getSessionItem(`${keyPrefix}_${key}`);
        if (value && value !== "undefined") {
          return JSON.parse(value);
        }
        return null;
      } else if (hasCookies()) {
        const value = getCookie(`${keyPrefix}_${key}`);
        if (value && value !== "undefined") {
          return JSON.parse(value);
        }
        return null;
      }
    },
  };
}

export default Util;

const getKeyPrefix = () => {
  return document.domain.replace(".qualifico.com", "").replace(".", "_");
};
